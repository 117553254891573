<template>
    <div class="personal-area">
        <div class="personal-area__sidebar">
            <router-link
                to="/"
                class="logo personal-area__logo"
            ></router-link>
            <Menu class="personal-area__menu"/>
        </div>
        <div class="personal-area__content">
            <div class="personal-area__content-wrapper">
                <router-view/>
            </div>
            <div class="personal-area__footer">
                <Footer/>
            </div>
        </div>
    </div>
</template>

<script>
import Store from '@/store'
import './personal-area.scss'

import sbg from '@/mixins/sbg'

import Cookies from 'js-cookie'

import Footer from '@/components/common/footer/Footer'

export default {
    name: 'PersonalArea',
    mixins: [
        sbg
    ],
    computed: {
        tabs() {
            const cpa = []

            if (this.isSbg) {
                cpa.push(
                    {
                        title: 'Мои предложения',
                        link: {
                            name: 'PersonalAreaLoans'
                        }
                    }
                )
            }

            return [
                ...cpa,
                {
                    title: 'Услуги',
                    link: {
                        name: 'PersonalAreaServices'
                    }
                },
                {
                    title: 'Мой профиль',
                    link: {
                        name: 'PersonalAreaProfile'
                    }
                },
            ]
        }
    },
    components: {
        Menu: () => import('./components/menu/Menu'),
        Footer
    },
    async beforeRouteEnter(to, from, next) {
        if (!Cookies.get('sbg-in')) {
            Store.commit('application/load', false)
            next('/')
        } else {
            await Store.dispatch('personal/getInfo')
            await Store.dispatch('application/update')
            next()
        }
    }
}
</script>